
















































import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  data() {
    return {
      error: null as null|Error,
      isLoginLink: false,
      loading: false,
      success: false,
    };
  },
  methods: {
    async confirm() {
      this.loading = true;
      this.error = null;

      const attempt = await this.$store.dispatch('auth/VerifyEmailLinkSecret', {
        form: {
          attempt_id: this.$route.params.ATTEMPT_ID,
          attempt_secret: this.$route.params.ATTEMPT_SECRET,
          secret: this.$route.params.EMAIL_LINK_SECRET,
        },
      }).catch((e: any) => { this.error = e; });

      this.loading = false;

      if (!this.error) this.success = true;

      this.isLoginLink = attempt.purpose === 'Login';
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
